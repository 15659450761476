.flyerEditor__textBold {
  font-weight: 700;
}

.flyerEditor__textItalic {
  font-style: italic;
}

.flyerEditor__textUnderline {
  text-decoration: underline;
}

.flyerEditor__textStrikethrough {
  text-decoration: line-through;
}

.flyerEditor__textUnderlineStrikethrough {
  text-decoration: underline line-through;
}

.flyerEditor__mark {
  cursor: pointer;
  background-color: rgba(249, 148, 142, 0.5);
}
.flyerEditor__link{
  color: #3779eb
}
.flyerEditor__link:hover{
  color: #3779eb
}
